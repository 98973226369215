/* eslint-disable max-classes-per-file */
import { XF } from "./XF";

class CaliforniaTooltipTrigger {
  options = {
    delayIn: 200,
    delayInLoading: 800,
    delayOut: 200,
    trigger: "hover focus touchhold",
    maintain: false,
    clickHide: null,
    onShow: null,
    onHide: null,
  };

  delayTimeout = null;

  delayTimeoutType = null;

  stopFocusBefore = null;

  clickTriggered = false;

  $covers = null;

  constructor(
    public $target: ReturnType<typeof $>,
    public tooltip: InstanceType<typeof XF.TooltipElement>,
    options: Partial<CaliforniaTooltipTrigger["options"]>
  ) {
    this.options = $.extend(true, {}, this.options, options);

    if (this.options.trigger === "auto") {
      this.options.trigger = `hover focus${
        $target.is("span") ? " touchclick" : ""
      }`;
    }

    tooltip.setPositioner($target);
    tooltip.addSetupCallback(XF.proxy(this, "onTooltipSetup"));

    $target.xfUniqueId();
    XF.TooltipTrigger.cache[$target.attr("id")] = this;
  }

  init() {
    const { $target } = this;
    let actOnClick = false;
    let actOnTouchClick = false;
    const supportsPointerEvents = XF.supportsPointerEvents();
    const pointerEnter = supportsPointerEvents ? "pointerenter" : "mouseenter";
    const pointerLeave = supportsPointerEvents ? "pointerleave" : "mouseleave";

    if (this.options.clickHide === null) {
      this.options.clickHide = $target.is("a");
    }

    const triggers = this.options.trigger.split(" ");
    for (let i = 0; i < triggers.length; i++) {
      switch (triggers[i]) {
        case "hover":
          $target
            .on(`${pointerEnter}.tooltip`, XF.proxy(this, "mouseEnter"))
            .on(`${pointerLeave}.tooltip`, XF.proxy(this, "leave"));
          break;

        case "focus":
          $target.on({
            "focusin.tooltip": XF.proxy(this, "focusEnter"),
            "focusout.tooltip": XF.proxy(this, "leave"),
          });
          break;

        case "click":
          actOnClick = true;

          $target.onPointer("click.tooltip", XF.proxy(this, "click"));
          $target.onPointer("auxclick.tooltip contextmenu.tooltip", () => {
            this.cancelShow();
            this.stopFocusBefore = Date.now() + 2000;
          });
          break;

        case "touchclick":
          actOnTouchClick = true;
          $target.onPointer("click.tooltip", (e) => {
            if (XF.isEventTouchTriggered(e)) {
              this.click(e);
            }
          });
          break;

        case "touchhold":
          actOnTouchClick = true;

          $target.data("threshold", this.options.delayIn);

          $target.onPointer({
            "touchstart.tooltip": function onTouchStart() {
              $target.data("tooltip:touching", true);
            },
            "touchend.tooltip": function onTouchEnd() {
              setTimeout(() => {
                $target.removeData("tooltip:touching");
              }, 50);
            },
            "taphold.tooltip": (e) => {
              $target.data("tooltip:taphold", true);
              if (XF.isEventTouchTriggered(e)) {
                this.click(e);
              }
            },
            "contextmenu.tooltip": function onContextMenu(e) {
              if ($target.data("tooltip:touching")) {
                e.preventDefault();
              }
            },
          });
          break;

        default:
          break;
      }
    }

    if (actOnClick && actOnTouchClick) {
      console.error("Cannot have touchclick and click triggers");
    }

    if (!actOnClick && this.options.clickHide) {
      $target.onPointer(
        "click.tooltip auxclick.tooltip contextmenu.tooltip",
        (e) => {
          if (actOnTouchClick && XF.isEventTouchTriggered(e)) {
            // other event already triggered
            return;
          }

          this.hide();
          this.stopFocusBefore = Date.now() + 2000;
        }
      );
    }

    $target.on({
      "tooltip:show": XF.proxy(this, "show"),
      "tooltip:hide": XF.proxy(this, "hide"),
      "tooltip:reposition": XF.proxy(this, "reposition"),
    });
  }

  reposition() {
    this.tooltip.reposition();
  }

  click(e) {
    if (e.button > 0 || e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) {
      // non-primary clicks should prevent any hover behavior
      this.cancelShow();
      return;
    }

    if (this.tooltip.isShown()) {
      if (window.innerWidth > 900) {
        window.location = this.$target.attr("href");
        return;
      }

      if (!this.tooltip.isShownFully()) {
        // a click before the tooltip has finished animating or loading, so act as if the click triggered
        e.preventDefault();
        this.clickShow(e);
        return;
      }

      this.hide();
    } else if (window.innerWidth <= 900) {
      e.preventDefault();
      this.clickShow(e);
    } else {
      window.location.href = this.$target.attr("href") || "";
    }
  }

  clickShow(e) {
    this.clickTriggered = true;

    setTimeout(() => {
      const $covers = this.addCovers();

      if (XF.isEventTouchTriggered(e)) {
        $covers.addClass("is-active");
      } else {
        $(document).on(
          `click.tooltip-${this.$target.xfUniqueId()}`,
          XF.proxy(this, "docClick")
        );
      }
    }, 0);

    this.show();
  }

  addCovers() {
    if (this.$covers) {
      this.$covers.remove();
    }

    const dimensions = this.$target.dimensions(true);
    const boxes = [];

    // above
    boxes.push({
      top: 0,
      height: dimensions.top,
      left: 0,
      right: 0,
    });

    // left
    boxes.push({
      top: dimensions.top,
      height: dimensions.height,
      left: 0,
      width: dimensions.left,
    });

    // right
    boxes.push({
      top: dimensions.top,
      height: dimensions.height,
      left: dimensions.right,
      right: 0,
    });

    // below
    boxes.push({
      top: dimensions.bottom,
      height: $("html").height() - dimensions.bottom,
      left: 0,
      right: 0,
    });

    let $covers = $();
    let $box;
    for (let i = 0; i < boxes.length; i++) {
      $box = $('<div class="tooltipCover" />').css(boxes[i]);
      $covers = $covers.add($box);
    }

    $covers.on("click", XF.proxy(this, "hide"));

    this.tooltip.getTooltip().before($covers);
    this.$covers = $covers;

    XF.setRelativeZIndex($covers, this.$target);

    return $covers;
  }

  docClick(e) {
    const { $covers } = this;
    let { pageX } = e;
    let { pageY } = e;
    const $window = $(window);

    if (!$covers) {
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (e.screenX == 0 && e.screenY == 0) {
      const dimensions = $(e.target).dimensions();
      pageX = dimensions.left;
      pageY = dimensions.top;
    }

    $covers.addClass("is-active");
    const $clicked = $(
      document.elementFromPoint(
        pageX - $window.scrollLeft(),
        pageY - $window.scrollTop()
      )
    );
    $covers.removeClass("is-active");

    if ($clicked.is($covers)) {
      this.hide();
    }
  }

  mouseEnter(e) {
    if (XF.isEventTouchTriggered(e)) {
      // make touch tooltips only trigger on click
      return;
    }

    this.enter();
  }

  focusEnter(e) {
    if (Date.now() - XF.pageDisplayTime < 100) {
      return;
    }

    if (XF.isEventTouchTriggered(e)) {
      // touch focus is likely a long press so don't trigger a tooltip for that
      // (make touch tooltips only trigger on click)
      return;
    }

    // there are situations where a focus event happens and we don't want it to trigger a display
    if (!this.stopFocusBefore || Date.now() >= this.stopFocusBefore) {
      this.enter();
    }
  }

  enter() {
    if (this.isShown() && this.clickTriggered) {
      // already shown by a click, don't trigger anything else
      return;
    }

    this.clickTriggered = false;

    const delay = this.tooltip.requiresLoad()
      ? this.options.delayInLoading
      : this.options.delayIn;
    if (!delay) {
      this.show();
      return;
    }

    if (this.delayTimeoutType !== "enter") {
      this.resetDelayTimer();
    }

    if (!this.delayTimeoutType && !this.isShown()) {
      this.delayTimeoutType = "enter";

      this.delayTimeout = setTimeout(() => {
        this.delayTimeoutType = null;
        this.show();
      }, delay);
    }
  }

  leave() {
    if (this.clickTriggered) {
      // when click toggled, only an explicit other action closes this
      return;
    }

    const delay = this.options.delayOut;
    if (!delay) {
      this.hide();
      return;
    }

    if (this.delayTimeoutType !== "leave") {
      this.resetDelayTimer();
    }

    if (!this.delayTimeoutType && this.isShown()) {
      this.delayTimeoutType = "leave";

      this.delayTimeout = setTimeout(() => {
        this.delayTimeoutType = null;
        this.hide();
      }, delay);
    }
  }

  show() {
    $(window)
      .off(`focus.tooltip-${this.$target.xfUniqueId()}`)
      .on(`focus.tooltip-${this.$target.xfUniqueId()}`, () => {
        this.stopFocusBefore = Date.now() + 250;
      });

    let zIndex = XF.getElEffectiveZIndex($("header"));
    $(".menu").each((_, element) => {
      zIndex = Math.max(zIndex, XF.getElEffectiveZIndex($(element)));
    });
    XF.setRelativeZIndex(
      this.tooltip.getTooltip(),
      this.$target,
      null,
      (zIndex ?? 0) + 1
    );

    if (this.options.onShow) {
      const cb = this.options.onShow;
      cb(this, this.tooltip);
    }

    this.tooltip.show();
  }

  cancelShow() {
    if (this.delayTimeoutType === "enter") {
      this.resetDelayTimer();
    } else if (!this.tooltip.isShownFully()) {
      this.hide();
    }
  }

  hide() {
    this.tooltip.hide();
    this.resetDelayTimer();
    this.clickTriggered = false;

    if (this.$covers) {
      this.$covers.remove();
      this.$covers = null;
    }

    $(document).off(`click.tooltip-${this.$target.xfUniqueId()}`);

    if (this.options.onHide) {
      const cb = this.options.onHide;
      cb(this, this.tooltip);
    }
  }

  toggle() {
    if (this.isShown()) this.hide();
    else this.show();
  }

  isShown() {
    return this.tooltip.isShown();
  }

  wasClickTriggered() {
    return this.clickTriggered;
  }

  resetDelayTimer() {
    if (this.delayTimeoutType) {
      clearTimeout(this.delayTimeout);
      this.delayTimeoutType = null;
    }
  }

  addMaintainElement($el) {
    if ($el.data("tooltip-maintain")) {
      return;
    }

    const triggers = this.options.trigger.split(" ");
    for (let i = 0; i < triggers.length; i++) {
      switch (triggers[i]) {
        case "hover":
          $el.on("mouseenter.tooltip", XF.proxy(this, "enter"));
          $el.on("mouseleave.tooltip", XF.proxy(this, "leave"));
          break;

        case "focus":
          $el.on("focusin.tooltip", XF.proxy(this, "enter"));
          $el.on("focusout.tooltip", XF.proxy(this, "leave"));
          break;

        default:
          break;
      }
    }

    $el.data("tooltip-maintain", true);
  }

  // eslint-disable-next-line class-methods-use-this
  removeMaintainElement($el) {
    $el.off(".tooltip");
    $el.data("tooltip-maintain", false);
  }

  onTooltipSetup($tooltip) {
    if (this.options.maintain) {
      this.addMaintainElement($tooltip);

      $tooltip.on("menu:opened", (e, $menu) => {
        this.addMaintainElement($menu);
      });
      $tooltip.on("menu:closed", (e, $menu) => {
        this.removeMaintainElement($menu);
      });
    }
  }
}

class CaliforniaTooltipElement {
  options = {
    baseClass: "tooltip",
    extraClass: "tooltip--basic",
    html: false,
    inViewport: true,
    loadRequired: false,
    loadParams: null,
    placement: "top",
    verticalPositionOffset: 0,
  };

  content = null;

  $tooltip = null;

  shown = false;

  shownFully = false;

  placement = null;

  positioner = null;

  loadRequired = false;

  loading = false;

  contentApplied = false;

  setupCallbacks = null;

  constructor(
    content,
    options: Partial<CaliforniaTooltipElement["options"]>,
    positioner
  ) {
    this.setupCallbacks = []; // needs to be set here to be local

    this.options = $.extend(true, {}, this.options, options);
    this.content = content;
    this.loadRequired = this.options.loadRequired;

    if (positioner) {
      this.setPositioner(positioner);
    }
  }

  setPositioner(positioner) {
    this.positioner = positioner;
  }

  setLoadRequired(required) {
    this.loadRequired = required;
  }

  addSetupCallback(callback) {
    if (this.$tooltip) {
      // already setup, run now
      callback(this.$tooltip);
    } else {
      this.setupCallbacks.push(callback);
    }
  }

  show() {
    if (this.shown) {
      return;
    }

    this.shown = true;

    if (this.loadRequired) {
      this.loadContent();
      return;
    }

    const $tooltip = this.getTooltip();

    this.reposition();

    $(window).on(
      `resize.tooltip-${$tooltip.xfUniqueId()}`,
      XF.proxy(this, "reposition")
    );

    $tooltip
      .trigger("tooltip:shown")
      .stop()
      .css({
        visibility: "",
        display: "none",
      })
      .fadeIn("fast", () => {
        this.shownFully = true;
      });
  }

  hide() {
    if (!this.shown) {
      return;
    }

    this.shown = false;
    this.shownFully = false;

    const { $tooltip } = this;
    if ($tooltip) {
      $tooltip.stop().fadeOut("fast").trigger("tooltip:hidden");

      $(window).off(`resize.tooltip-${$tooltip.xfUniqueId()}`);
    }
  }

  toggle() {
    if (this.shown) this.hide();
    else this.show();
  }

  destroy() {
    if (this.$tooltip) {
      this.$tooltip.remove();
    }
  }

  isShown() {
    return this.shown;
  }

  isShownFully() {
    return this.shown && this.shownFully;
  }

  requiresLoad() {
    return this.loadRequired;
  }

  getPlacement() {
    return XF.rtlFlipKeyword(this.options.placement);
  }

  reposition() {
    const { positioner } = this;

    if (!positioner) {
      console.error("No tooltip positioner");
      return;
    }

    if (this.loadRequired) {
      return;
    }

    let targetDims;
    let forceInViewport = this.options.inViewport;

    if (positioner instanceof $) {
      targetDims = positioner.dimensions(true);

      if (positioner.closest(".overlay").length) {
        forceInViewport = true;
      }
    } else if (
      typeof positioner[0] !== "undefined" &&
      typeof positioner[1] !== "undefined"
    ) {
      // a single [x, y] point
      targetDims = {
        top: positioner[1],
        right: positioner[0],
        bottom: positioner[1],
        left: positioner[0],
      };
    } else if (
      typeof positioner.right !== "undefined" &&
      typeof positioner.bottom !== "undefined"
    ) {
      // positioner is already t/r/b/l format
      targetDims = positioner;
    } else {
      console.error("Positioner is not in correct format", positioner);
    }

    targetDims.width = targetDims.right - targetDims.left;
    targetDims.height = targetDims.bottom - targetDims.top;

    const $tooltip = this.getTooltip();
    let placement = this.getPlacement();
    const { baseClass } = this.options;
    const originalPlacement = placement;
    let constraintDims;

    if (forceInViewport) {
      const $window = $(window);
      const vwHeight = $window.height();
      const vwWidth = $window.width();
      let vwTop = $window.scrollTop();
      const vwLeft = $window.scrollLeft();
      const stickyHeaders = XF.Element.getHandlers("sticky-header");
      if (stickyHeaders) {
        if (
          stickyHeaders[0].$target.hasClass(
            stickyHeaders[0].options.stickyClass
          )
        ) {
          vwTop += stickyHeaders[0].$target.outerHeight();
        }
      }

      constraintDims = {
        top: vwTop,
        left: vwLeft,
        right: vwLeft + vwWidth,
        bottom: vwTop + vwHeight,
        width: vwWidth,
        height: vwHeight,
      };
    } else {
      constraintDims = $("body").dimensions();
    }

    if (this.placement) {
      $tooltip.removeClass(`${baseClass}--${this.placement}`);
    }

    $tooltip.addClass(`${baseClass}--${placement}`).css({
      visibility: "hidden",
      display: "block",
      top: "",
      bottom: "",
      left: "",
      right: "",
      "padding-left": "",
      "padding-right": "",
      "padding-top": "",
      "padding-bottom": "",
    });

    const tooltipWidth = $tooltip.outerWidth();
    const tooltipHeight = $tooltip.outerHeight();

    // can we fit this in the right position? if not, flip it
    // if still can't fit horizontally, go vertical
    if (
      placement === "top" &&
      targetDims.top - tooltipHeight < constraintDims.top
    ) {
      placement = "bottom";
    } else if (
      placement === "bottom" &&
      targetDims.bottom + tooltipHeight > constraintDims.bottom
    ) {
      if (targetDims.top - tooltipHeight >= constraintDims.top) {
        // only flip this back to the top if we have space within the constraints
        placement = "top";
      }
    } else if (
      placement === "left" &&
      targetDims.left - tooltipWidth < constraintDims.left
    ) {
      if (targetDims.right + tooltipWidth > constraintDims.right) {
        if (targetDims.top - tooltipHeight < constraintDims.top) {
          placement = "bottom";
        } else {
          placement = "top";
        }
      } else {
        placement = "right";
      }
    } else if (
      placement === "right" &&
      targetDims.right + tooltipWidth > constraintDims.right
    ) {
      if (targetDims.left - tooltipWidth < constraintDims.left) {
        if (targetDims.top - tooltipHeight < constraintDims.top) {
          placement = "bottom";
        } else {
          placement = "top";
        }
      } else {
        placement = "left";
      }
    }
    // eslint-disable-next-line eqeqeq
    if (placement != originalPlacement) {
      $tooltip
        .removeClass(`${baseClass}--${originalPlacement}`)
        .addClass(`${baseClass}--${placement}`);
    }

    // figure out how to place the edges
    const position = {
      top: "",
      right: "",
      bottom: "",
      left: "",
    };
    switch (placement) {
      case "top":
        position.bottom =
          $(window).height() -
          targetDims.top +
          this.options.verticalPositionOffset;
        position.left =
          targetDims.left + targetDims.width / 2 - tooltipWidth / 2;
        break;

      case "bottom":
        position.top = targetDims.bottom + this.options.verticalPositionOffset;
        position.left =
          targetDims.left + targetDims.width / 2 - tooltipWidth / 2;
        break;

      case "left":
        position.top =
          targetDims.top + targetDims.height / 2 - tooltipHeight / 2;
        position.right = $(window).width() - targetDims.left;
        break;

      case "right":
      default:
        position.top =
          targetDims.top + targetDims.height / 2 - tooltipHeight / 2;
        position.left = targetDims.right;
    }

    $tooltip.css(position);

    const tooltipDims = $tooltip.dimensions(true);
    const delta = { top: 0, left: 0 };
    const $arrow = $tooltip.find(`.${baseClass}-arrow`);

    // Check to see if we're outside of the constraints on the opposite edge from our positioned side
    // and if we are, push us down to that position and move the arrow to be positioned nicely.
    // We will only move the top positioning when doing left/right and left using top/bottom.
    if (placement === "left" || placement === "right") {
      if (tooltipDims.top < constraintDims.top) {
        delta.top = constraintDims.top - tooltipDims.top;
      } else if (tooltipDims.bottom > constraintDims.bottom) {
        delta.top = constraintDims.bottom - tooltipDims.bottom;
      }

      $arrow.css({
        left: "",
        top: `${50 - (100 * delta.top) / tooltipDims.top}%`,
      });
    } else {
      if (tooltipDims.left < constraintDims.left) {
        delta.left = constraintDims.left - tooltipDims.left;
      } else if (tooltipDims.left + tooltipWidth > constraintDims.right) {
        delta.left = constraintDims.right - (tooltipDims.left + tooltipWidth);
      }

      const arrowLeft = parseInt(
        (tooltipWidth / 100) * (50 - (100 * delta.left) / tooltipWidth),
        10
      );
      const arrowRealLeft = arrowLeft + parseInt($arrow.css("margin-left"), 10);
      const arrowRealRight = arrowRealLeft + $arrow.outerWidth();
      const tooltipLeftOffset = parseInt($tooltip.css("padding-left"), 10);
      const tooltipRightOffset = parseInt($tooltip.css("padding-right"), 10);
      let shiftDiff;

      // detect if the arrow is going to spill out of the main container and adjust the container
      // padding to keep the width the same but to shift it
      if (arrowRealLeft < tooltipLeftOffset) {
        shiftDiff = tooltipLeftOffset - arrowRealLeft;
        $tooltip.css({
          "padding-left": Math.max(0, tooltipLeftOffset - shiftDiff),
          "padding-right": tooltipRightOffset + shiftDiff,
        });
      } else if (arrowRealRight > tooltipWidth - tooltipRightOffset) {
        shiftDiff = arrowRealRight - (tooltipWidth - tooltipRightOffset);
        $tooltip.css({
          "padding-left": tooltipRightOffset + shiftDiff,
          "padding-right": Math.max(0, tooltipRightOffset - shiftDiff),
        });
      }

      $arrow.css({
        top: "",
        left: arrowLeft,
      });
    }

    if (delta.left) {
      $tooltip.css("left", position.left + delta.left);
    } else if (delta.top) {
      $tooltip.css("top", position.top + delta.top);
    }

    this.placement = placement;

    if (this.shown && !this.loadRequired) {
      $tooltip.css("visibility", "");
    }
  }

  attach() {
    this.getTooltip();
  }

  getTooltip() {
    if (!this.$tooltip) {
      const $tooltip = this.getTemplate();
      $tooltip.appendTo("body");
      this.$tooltip = $tooltip;

      if (!this.loadRequired) {
        this.applyTooltipContent();
      }
    }

    return this.$tooltip;
  }

  applyTooltipContent() {
    if (this.contentApplied || this.loadRequired) {
      return false;
    }

    const $tooltip = this.getTooltip();
    const $contentHolder = $tooltip.find(`.${this.options.baseClass}-content`);
    let { content } = this;

    if (typeof content === "function") {
      content = content();
    }

    if (this.options.html) {
      $contentHolder.html(content);
      $contentHolder.find("img").on("load", XF.proxy(this, "reposition"));
    } else {
      $contentHolder.text(content);
    }

    const setup = this.setupCallbacks;
    for (let i = 0; i < setup.length; i++) {
      setup[i]($tooltip);
    }

    XF.activate($tooltip);

    this.contentApplied = true;
    return true;
  }

  loadContent() {
    if (!this.loadRequired || this.loading) {
      return;
    }

    const { content } = this;

    const onLoad = (newContent) => {
      this.content = newContent;
      this.loadRequired = false;
      this.loading = false;
      this.applyTooltipContent();

      if (this.shown) {
        this.shown = false; // make sure the show works
        this.show();
      }
    };

    if (typeof content !== "function") {
      onLoad("");
      return;
    }

    this.loading = true;
    content(onLoad, this.options.loadParams);
  }

  getTemplate() {
    const extra = this.options.extraClass ? ` ${this.options.extraClass}` : "";
    const { baseClass } = this.options;

    return $(
      $.parseHTML(
        `<div class="${baseClass}${extra}" role="tooltip">` +
          `<div class="${baseClass}-arrow"></div>` +
          `<div class="${baseClass}-content"></div>` +
          `</div>`
      )
    );
  }
}

const CaliforniaTooltipOptions = {
  base: {
    // tooltip options
    baseClass: "tooltip",
    extraClass: "tooltip--basic",
    html: false,
    inViewport: true,
    placement: "top",
    verticalPositionOffset: 0,

    // trigger options
    clickHide: null,
    delayIn: 200,
    delayOut: 200,
    maintain: false,
    trigger: "hover focus",
  },
  tooltip: [
    "baseClass",
    "extraClass",
    "html",
    "placement",
    "verticalPositionOffset",
  ] as const,
  trigger: ["clickHide", "delayIn", "delayOut", "maintain", "trigger"] as const,
  extract<T, K extends keyof T>(keys: K[], values: T): Pick<T, K> {
    const o: Pick<T, K> = {};
    for (let i = 0; i < keys.length; i++) {
      o[keys[i]] = values[keys[i]];
    }

    return o;
  },
  extractTooltip(values) {
    return this.extract(this.tooltip, values);
  },
  extractTrigger(values) {
    return this.extract(this.trigger, values);
  },
};

XF.Element.extend("member-tooltip", {
  options: {
    delay: 250,
  },

  userId: undefined as string | undefined,
  tooltip: undefined as InstanceType<typeof XF.TooltipElement> | undefined,
  trigger: undefined as CaliforniaTooltipTrigger | undefined,

  init() {
    this.userId = this.$target.data("user-id");

    this.tooltip = new XF.TooltipElement(XF.proxy(this, "getContent"), {
      extraClass: "tooltip--member",
      html: true,
      loadRequired: true,
    });

    this.trigger = new CaliforniaTooltipTrigger(this.$target, this.tooltip, {
      maintain: true,
      delayInLoading: this.options.delay,
      delayIn: this.options.delay,
      trigger: "hover focus click",
      onShow: XF.proxy(this, "onShow"),
      onHide: XF.proxy(this, "onHide"),
    });

    this.trigger.init();
  },
});

XF.Element.extend("share-tooltip", {
  onShow() {
    if (this.trigger.wasClickTriggered()) {
      const shareButton = this.getTarget().get(0);
      shareButton?.classList.add("is-selected");
    }

    const { activeTooltip } = XF.ShareTooltip;
    if (activeTooltip && activeTooltip !== this) {
      activeTooltip.hide();
    }

    XF.ShareTooltip.activeTooltip = this;
  },

  onHide() {
    const shareButton = this.getTarget().get(0);
    shareButton?.classList.remove("is-selected");

    if (XF.ShareTooltip.activeTooltip === this) {
      XF.ShareTooltip.activeTooltip = null;
    }
  },

  loaded(data, onContent) {
    if (!data.html) {
      return;
    }

    const bindCloseButton = this.bindCloseButton.bind(this);

    XF.setupHtmlInsert(data.html, ($html) => {
      onContent($html);
      bindCloseButton();
    });
  },

  getTarget() {
    return this.$target;
  },

  getTooltip() {
    return this.tooltip.$tooltip;
  },

  bindCloseButton() {
    const tooltipElement = this.getTooltip().get(0);
    const closeButton = tooltipElement.querySelector(".js-shareTooltip-close");

    closeButton?.addEventListener("click", () => {
      this.hide();
    });
  },
});

const CaliforniaTooltip = XF.Element.newHandler({
  options: $.extend(true, {}, CaliforniaTooltipOptions.base, {
    content: null,
  }),

  trigger: null as InstanceType<typeof XF.TooltipTrigger> | null,
  tooltip: null as CaliforniaTooltipElement | null,

  init() {
    const tooltipContent = this.getContent();
    const tooltipOptions = CaliforniaTooltipOptions.extractTooltip(
      this.options
    );
    const triggerOptions = CaliforniaTooltipOptions.extractTrigger(
      this.options
    );

    this.tooltip = new CaliforniaTooltipElement(tooltipContent, tooltipOptions);
    this.trigger = new XF.TooltipTrigger(
      this.$target,
      this.tooltip,
      triggerOptions
    );

    this.trigger.init();
  },

  getContent() {
    if (this.options.content) {
      return this.options.content;
    }
    const { $target } = this;
    const title =
      $target.attr("data-original-title") || $target.attr("title") || "";

    $target.attr("data-original-title", title).removeAttr("title");

    return title;
  },
});

XF.CaliforniaElementTooltip = XF.extend(CaliforniaTooltip, {
  __backup: {
    getContent: "_getContent",
    init: "_init",
  },

  options: $.extend({}, CaliforniaTooltip.prototype.options, {
    element: null,
    showError: true,
    noTouch: true,
    shortcut: null,
  }),

  $element: null,

  init() {
    if (this.options.shortcut) {
      this.setupShortcut(this.options.shortcut);
    }

    if (this.options.noTouch && XF.Feature.has("touchevents")) {
      return;
    }

    const { element } = this.options;
    const { showError } = this.options;

    if (!element) {
      if (showError) {
        console.error("No element specified for the element tooltip");
      }
      return;
    }

    const $element = XF.findRelativeIf(element, this.$target);
    if (!$element.length) {
      if (showError) {
        console.error(`Element tooltip could not find ${element}`);
      }

      return;
    }

    this.$element = $element;
    this.$target.removeAttr("title");
    this.options.html = true;
    // eslint-disable-next-line no-underscore-dangle
    this._init();
  },

  setupShortcut(shortcut: string) {
    if (shortcut === "node-description") {
      if (!this.options.element) {
        this.options.element = "< .js-nodeMain | .js-nodeDescTooltip";
      }

      this.options.showError = false;
      this.options.maintain = true;
      this.options.placement = "right";
      this.options.extraClass = "tooltip--basic tooltip--description";
    }
  },

  getContent() {
    return this.$element.clone().contents();
  },
});

XF.Element.register("element-tooltip", "XF.CaliforniaElementTooltip");
